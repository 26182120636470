import { combineReducers } from 'redux'
import customerReducer from './customerReducer'
import merchantReducer from './merchantReducer'
import paymentMethodReducer from './paymentMethodReducer'
import RekeningReducer from './rekeningReducer'
import transactionReducer from './transactionReducer'
import validateReducer from './validateReducer'

export default combineReducers({
  customer: customerReducer,
  merchant: merchantReducer,
  paymentMethod: paymentMethodReducer,
  transaction: transactionReducer,
  validate: validateReducer,
  rekening: RekeningReducer,
})
