import RekeningNumber from 'components/rekeningNumber'
import Footer from 'components/Footer'

const paymentPageLayout = ({
  bankVA,
  redirectFinish,
  title,
  children,
  src,
  alt,
}: IpaymentPageLayout) => {
  return (
    <div className="paymentPageLayout">
      <div>
        <RekeningNumber title={title} alt={alt} logo={src} vaDetail={bankVA} />
        {children}
      </div>
      <Footer submit={redirectFinish} textFooter={'Confirm Payment'} bottom />
    </div>
  )
}

export default paymentPageLayout
