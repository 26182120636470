import {
  IPaymentMethodState,
  IPaymentMethod,
  PaymentMethodTypes,
} from './../types/paymentMethodTypes'

// 2,3,9,10,11,13,17,22,23,24.25,26,27,28,29

const initialState = {
  paymentMethod: {
    id: 0,
    name: '',
    page_id: 0,
    picture: {
      url: '',
    },
  },
  paymentMethodList: [],
  //   },
  //   {
  //     id: 2,
  //     name: 'Direct Debit',
  //     picture: {
  //       url:
  //         'https://s3.loyalto.id/qoinstaging/imagepublic/wNJwFhbX1595227603-663dab8df45c1671218542f69ed3f743.png',
  //     },
  //     page_id: 1,
  //     chanels: [
  //       {
  //         id: 4,
  //         name: 'BRI e-Pay',
  //         picture: {
  //           url:
  //             'https://s3.loyalto.id/qoindev/imagepublic/ZiIzkFdY1589360131-44af92d2ba862fea3b607835781671e1.png',
  //         },
  //         page_id: 5,
  //       },
  //       {
  //         id: 5,
  //         name: 'BCA Klik Pay',
  //         picture: {
  //           url:
  //             'https://s3.loyalto.id/qoindev/imagepublic/HgEhZiun1589360148-cb6029307af2f8099020cef6e24dfc47.png',
  //         },
  //         page_id: 6,
  //       },
  //     ],
  //   },
  //   {
  //     id: 3,
  //     name: 'Bank Transfer',
  //     picture: {
  //       url:
  //         'https://s3.loyalto.id/qoindev/imagepublic/uWXZGpks1589360042-4e525c6804e63676c3d89dfca159b5e5.svg',
  //     },
  //     page_id: 2,
  //     chanels: [
  //       {
  //         id: 6,
  //         name: 'Bank NTT',
  //         picture: {
  //           url:
  //             'https://s3.loyalto.id/qoindev/imagepublic/gifznr1f1637230276-bae071d330aa48529a5df61fa6a29dd4.png',
  //         },
  //         page_id: 22,
  //       },
  //       {
  //         id: 7,
  //         name: 'Mandiri Bill Pay',
  //         picture: {
  //           url:
  //             'https://s3.loyalto.id/qoindev/imagepublic/CmuhxhCb1589360162-4b339687f85e46ccf28df7b438954db0.png',
  //         },
  //         page_id: 7,
  //       },
  //       {
  //         id: 8,
  //         name: 'BCA Virtual Account',
  //         picture: {
  //           url:
  //             'https://s3.loyalto.id/qoindev/imagepublic/dRBRSxdA1589360172-fcbd13ef563f044b0b6db5aded087293.png',
  //         },
  //         page_id: 8,
  //       },
  //       {
  //         id: 9,
  //         name: 'BNI Virtual Account',
  //         picture: {
  //           url:
  //             'https://s3.loyalto.id/qoindev/imagepublic/zZsKTHQX1589360181-738abc695b6e81e635d4e4b8792e5f11.png',
  //         },
  //         page_id: 9,
  //       },
  //       {
  //         id: 10,
  //         name: 'ATM Bersama',
  //         picture: {
  //           url:
  //             'https://s3.loyalto.id/qoindev/imagepublic/5LwQF4Up1589360191-285ef98a86a437c6c59291c7d503e3aa.png',
  //         },
  //         page_id: 4,
  //       },
  //       {
  //         id: 14,
  //         name: 'BNI Virtual Account',
  //         picture: {
  //           url:
  //             'https://s3.loyalto.id/pg-staging/payment-method/OwGP4qnt1669606365-ac94c77c3a0f9670b3e74bd4b730bd98.png',
  //         },
  //         page_id: 24,
  //       },
  //       {
  //         id: 15,
  //         name: 'BRI Virtual Account',
  //         picture: {
  //           url:
  //             'https://s3.loyalto.id/pg-staging/payment-method/6WbWIAnO1669606433-f1e7682eedd8c8e6d4ee0e04a2be7fb3.png',
  //         },
  //         page_id: 25,
  //       },
  //       {
  //         id: 16,
  //         name: 'CIMB Virtual Account',
  //         picture: {
  //           url:
  //             'https://s3.loyalto.id/pg-staging/payment-method/fLufJeOO1669606331-4ff8fa9eaaea0d50f58748ce821e1fad.png',
  //         },
  //         page_id: 26,
  //       },
  //       {
  //         id: 17,
  //         name: 'Mandiri Virtual Account',
  //         picture: {
  //           url:
  //             'https://s3.loyalto.id/pg-staging/payment-method/jxagOpr41669606450-1ac7c3c70b5d16af3f596a1a488f9286.png',
  //         },
  //         page_id: 27,
  //       },
  //       {
  //         id: 18,
  //         name: 'Permata Virtual Account',
  //         picture: {
  //           url:
  //             'https://s3.loyalto.id/pg-staging/payment-method/Vj3nf7KG1669606379-e13aed88976d8c2dd6a821226c13b9f7.png',
  //         },
  //         page_id: 28,
  //       },
  //       {
  //         id: 19,
  //         name: 'BCA Virtual Account',
  //         picture: {
  //           url:
  //             'https://s3.loyalto.id/pg-staging/payment-method/mQNawudv1669606350-1a227a403b04e788150e8c833397dcba.png',
  //         },
  //         page_id: 29,
  //       },
  //       {
  //         id: 20,
  //         name: 'AGI Virtual Account',
  //         picture: {
  //           url:
  //             'https://s3.loyalto.id/qoindev/imagepublic/6BRwRv571630033078-3811b2464181c550f8d62493c93bd59a.jpg',
  //         },
  //         page_id: 17,
  //       },
  //     ],
  //   },
  //   {
  //     id: 1,
  //     name: 'Debit/Credit Card',
  //     picture: {
  //       url:
  //         'https://s3.loyalto.id/qoindev/imagepublic/DS8QNlN01656905955-a15f9b337efa35726b699077ed987546.png',
  //     },
  //     chanels: [
  //       {
  //         id: 2,
  //         name: 'Credit Card',
  //         picture: {
  //           url:
  //             'https://s3.loyalto.id/qoindev/imagepublic/9KtQygMu1589359692-8bfe38ce5e14c1a62b3020c532b25dcd.png',
  //         },
  //         page_id: 2,
  //       },
  //     ],
  //   },
  //   {
  //     id: 2,
  //     name: 'Direct Debit',
  //     picture: {
  //       url:
  //         'https://s3.loyalto.id/qoinstaging/imagepublic/wNJwFhbX1595227603-663dab8df45c1671218542f69ed3f743.png',
  //     },
  //     page_id: 1,
  //     chanels: [
  //       {
  //         id: 4,
  //         name: 'BRI e-Pay',
  //         picture: {
  //           url:
  //             'https://s3.loyalto.id/qoindev/imagepublic/ZiIzkFdY1589360131-44af92d2ba862fea3b607835781671e1.png',
  //         },
  //         page_id: 5,
  //       },
  //       {
  //         id: 5,
  //         name: 'BCA Klik Pay',
  //         picture: {
  //           url:
  //             'https://s3.loyalto.id/qoindev/imagepublic/HgEhZiun1589360148-cb6029307af2f8099020cef6e24dfc47.png',
  //         },
  //         page_id: 6,
  //       },
  //     ],
  //   },
  //   {
  //     id: 3,
  //     name: 'Bank Transfer',
  //     picture: {
  //       url:
  //         'https://s3.loyalto.id/qoindev/imagepublic/uWXZGpks1589360042-4e525c6804e63676c3d89dfca159b5e5.svg',
  //     },
  //     page_id: 2,
  //     chanels: [
  //       {
  //         id: 6,
  //         name: 'Bank NTT',
  //         picture: {
  //           url:
  //             'https://s3.loyalto.id/qoindev/imagepublic/gifznr1f1637230276-bae071d330aa48529a5df61fa6a29dd4.png',
  //         },
  //         page_id: 22,
  //       },
  //       {
  //         id: 7,
  //         name: 'Mandiri Bill Pay',
  //         picture: {
  //           url:
  //             'https://s3.loyalto.id/qoindev/imagepublic/CmuhxhCb1589360162-4b339687f85e46ccf28df7b438954db0.png',
  //         },
  //         page_id: 7,
  //       },
  //       {
  //         id: 8,
  //         name: 'BCA Virtual Account',
  //         picture: {
  //           url:
  //             'https://s3.loyalto.id/qoindev/imagepublic/dRBRSxdA1589360172-fcbd13ef563f044b0b6db5aded087293.png',
  //         },
  //         page_id: 8,
  //       },
  //       {
  //         id: 9,
  //         name: 'BNI Virtual Account',
  //         picture: {
  //           url:
  //             'https://s3.loyalto.id/qoindev/imagepublic/zZsKTHQX1589360181-738abc695b6e81e635d4e4b8792e5f11.png',
  //         },
  //         page_id: 9,
  //       },
  //       {
  //         id: 10,
  //         name: 'ATM Bersama',
  //         picture: {
  //           url:
  //             'https://s3.loyalto.id/qoindev/imagepublic/5LwQF4Up1589360191-285ef98a86a437c6c59291c7d503e3aa.png',
  //         },
  //         page_id: 4,
  //       },
  //       {
  //         id: 14,
  //         name: 'BNI Virtual Account',
  //         picture: {
  //           url:
  //             'https://s3.loyalto.id/pg-staging/payment-method/OwGP4qnt1669606365-ac94c77c3a0f9670b3e74bd4b730bd98.png',
  //         },
  //         page_id: 24,
  //       },
  //       {
  //         id: 15,
  //         name: 'BRI Virtual Account',
  //         picture: {
  //           url:
  //             'https://s3.loyalto.id/pg-staging/payment-method/6WbWIAnO1669606433-f1e7682eedd8c8e6d4ee0e04a2be7fb3.png',
  //         },
  //         page_id: 25,
  //       },
  //       {
  //         id: 16,
  //         name: 'CIMB Virtual Account',
  //         picture: {
  //           url:
  //             'https://s3.loyalto.id/pg-staging/payment-method/fLufJeOO1669606331-4ff8fa9eaaea0d50f58748ce821e1fad.png',
  //         },
  //         page_id: 26,
  //       },
  //       {
  //         id: 17,
  //         name: 'Mandiri Virtual Account',
  //         picture: {
  //           url:
  //             'https://s3.loyalto.id/pg-staging/payment-method/jxagOpr41669606450-1ac7c3c70b5d16af3f596a1a488f9286.png',
  //         },
  //         page_id: 27,
  //       },
  //       {
  //         id: 18,
  //         name: 'Permata Virtual Account',
  //         picture: {
  //           url:
  //             'https://s3.loyalto.id/pg-staging/payment-method/Vj3nf7KG1669606379-e13aed88976d8c2dd6a821226c13b9f7.png',
  //         },
  //         page_id: 28,
  //       },
  //       {
  //         id: 19,
  //         name: 'BCA Virtual Account',
  //         picture: {
  //           url:
  //             'https://s3.loyalto.id/pg-staging/payment-method/mQNawudv1669606350-1a227a403b04e788150e8c833397dcba.png',
  //         },
  //         page_id: 29,
  //       },
  //       {
  //         id: 20,
  //         name: 'AGI Virtual Account',
  //         picture: {
  //           url:
  //             'https://s3.loyalto.id/qoindev/imagepublic/6BRwRv571630033078-3811b2464181c550f8d62493c93bd59a.jpg',
  //         },
  //         page_id: 17,
  //       },
  //     ],
  //   },
  //   {
  //     id: 4,
  //     name: 'E-Money',
  //     picture: {
  //       url:
  //         'https://s3.loyalto.id/qoindev/imagepublic/s91G7KN71589876898-e6e97a4c02d897a30d731bafc2f83624.png',
  //     },
  //     page_id: 3,
  //     chanels: [
  //       {
  //         id: 11,
  //         name: 'Link Ajaa',
  //         picture: {
  //           url:
  //             'https://s3.loyalto.id/qoindev/imagepublic/ovqgJgFV1589360240-9016657d38ed3663ee26e24597c9ec4b.png',
  //         },
  //         page_id: 10,
  //       },
  //       {
  //         id: 12,
  //         name: 'OVO',
  //         picture: {
  //           url:
  //             'https://s3.loyalto.id/qoindev/imagepublic/VIQmYIuR1589360249-78a68bddaebe711ac4c2ec40b51e9f5b.png',
  //         },
  //         page_id: 11,
  //       },
  //       {
  //         id: 13,
  //         name: 'Yukk Cash',
  //         picture: {
  //           url:
  //             'https://s3.loyalto.id/pg-staging/payment-method/smTdCD0C1668666749-e58f6e720ade2c466176a415379af600.png',
  //         },
  //         page_id: 23,
  //       },
  //     ],
  //   },
  //   {
  //     id: 6,
  //     name: 'QRIS',
  //     picture: {
  //       url:
  //         'https://s3.loyalto.id/qoindev/imagepublic/xaMTtKKx1589360258-4797a419a3f740369f8bf71ac7d24a38.svg',
  //     },
  //     page_id: 4,
  //     chanels: [
  //       {
  //         id: 21,
  //         name: 'qris',
  //         picture: {
  //           url:
  //             'https://s3.loyalto.id/qoindev/imagepublic/xaMTtKKx1589360258-4797a419a3f740369f8bf71ac7d24a38.svg',
  //         },
  //         page_id: 13,
  //       },
  //     ],
  //   },
  // ],
  payment: false,
  isPaymentSubmit: false,
  isLoading: true,
  error: null,
}

const PaymentMethod = (
  state: IPaymentMethodState = initialState,
  { type, payload }: IPaymentMethod,
) => {
  switch (type) {
    case PaymentMethodTypes.SET_PAYMENTMETHOD_LIST:
      return { ...state, ...payload }
    case PaymentMethodTypes.SET_PAYMENTMETHOD:
      return { ...state, ...payload }
    case PaymentMethodTypes.CLEAR_PAYMENT_METHOD:
      return { ...state, ...payload }
    case PaymentMethodTypes.CLEAR_PAYMENT_METHODLIST:
      return { ...state, ...payload }
    default:
      return state
  }
}

export default PaymentMethod
