import React from 'react';

import './layout.scss';

interface ILayout {
  children: null | any | any[];
  styles?: Object;
}

const Layout = ({ children }: ILayout) => {
  return (
    <div className="layout-container">
      {children}
    </div>
  );
};

export default Layout;
