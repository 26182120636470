import { useEffect, useState } from 'react'
import Countdown, { zeroPad } from 'react-countdown'
import NumberFormat from 'react-number-format'
import { useSelector } from 'react-redux'
import { RootState } from 'stores'

import './layout.scss'
import logo from 'assets/qoin-logo2.png'

import Loader from 'components/loader/headerCard'
import LoaderDetail from 'components/loader/orderDetail'

import { faChevronUp, faChevronDown } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import i18n from 'config/i18n'
import moment from 'moment'
import { Collapse } from 'reactstrap'

const CardHeader = ({ isOpen, exp }: { isOpen?: boolean; exp: any }) => {
  const { name, orderID } = useSelector(
    (state: RootState) => state.merchant.merchantData,
  )
  const { price } = useSelector((state: RootState) => state.transaction.prices)
  const { showRekening } = useSelector((state: RootState) => state.rekening)

  const renderer = ({
    hours,
    minutes,
    seconds,
  }: {
    hours: any
    minutes: any
    seconds: any
  }) => {
    return (
      <div style={{ color: 'red', marginLeft: 10 }}>
        <span>
          {zeroPad(hours)}:{zeroPad(minutes)}:{zeroPad(seconds)}
        </span>
      </div>
    )
  }

  return (
    <>
      <div className="header-name">
        <b>{name}</b>
        <div className="Countdown">
          <div>{showRekening ? 'Pay within' : 'Choose within'}</div>
          <Countdown date={exp} renderer={renderer} />
        </div>
      </div>
      <div className="order-summary-section">
        <div className="header-amount">
          Rp.
          <NumberFormat
            className="header-amount"
            value={price}
            displayType={'text'}
            thousandSeparator={'.'}
            decimalSeparator={','}
            prefix={'  '}
          />
        </div>
        <br />
        <div className="header-order-id">
          Order ID {orderID}{' '}
          <div style={{ float: 'right' }}>
            <div>
              <FontAwesomeIcon icon={isOpen ? faChevronUp : faChevronDown} />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

const Detail = () => {
  const { name, phone, email } = useSelector(
    (state: RootState) => state.customer,
  )

  const [isCollaps, setisCollaps] = useState(false)

  const { orderDetail } = useSelector((state: RootState) => state.transaction)
  const { price } = useSelector((state: RootState) => state.transaction.prices)

  return (
    <div className=" order-detail-modal">
      <div>
        <div className="mt-2 mb-2">
          <b>Order Detail</b>
          {orderDetail.length > 3 && (
            <div style={{ float: 'right' }}>
              <div>
                <FontAwesomeIcon
                  className="mr-1"
                  size="sm"
                  onClick={() => setisCollaps(!isCollaps)}
                  icon={isCollaps ? faChevronUp : faChevronDown}
                />
              </div>
            </div>
          )}
        </div>

        {orderDetail.map((e, i) => {
          return (
            <div key={i}>
              {i < 3 && (
                <div className="list-price" key={i}>
                  <div className="d-flex flex-column w-50 ">
                    <div className="price">
                      {e.quantity} {e.name}
                    </div>
                  </div>
                  <div className="text-end">
                    Rp.
                    <NumberFormat
                      value={e.price}
                      displayType={'text'}
                      thousandSeparator={'.'}
                      decimalSeparator={','}
                      prefix={'  '}
                    />
                  </div>
                </div>
              )}

              {i > 3 && (
                <Collapse isOpen={isCollaps}>
                  <div className="list-price" key={i}>
                    <div className="d-flex flex-column w-50">
                      <div className="price">
                        {e.quantity} {e.name}
                      </div>
                    </div>
                    <div className="text-end">
                      Rp.
                      <NumberFormat
                        value={e.price}
                        displayType={'text'}
                        thousandSeparator={'.'}
                        decimalSeparator={','}
                        prefix={'  '}
                      />
                    </div>
                  </div>
                </Collapse>
              )}
            </div>
          )
        })}

        <div className="list-total">
          <div className="d-flex flex-column">
            <strong>Total</strong>
          </div>
          <strong>
            Rp.
            <NumberFormat
              value={price}
              displayType={'text'}
              thousandSeparator={'.'}
              decimalSeparator={','}
              prefix={'  '}
            />
          </strong>
        </div>
      </div>
      <div className="pb-3 mt-2">
        <b>Customer Detail</b>
        <div className="mt-2 ml-2">
          <b>{name}</b>
          <b style={{ float: 'right' }}>{phone}</b>
          <div>{email}</div>
        </div>
      </div>
    </div>
  )
}

const Header = ({ not }: { not?: boolean }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [isModal, setIsModal] = useState(false)
  const [exp, setExp] = useState(undefined)
  const [language, setLanguage] = useState('')

  const { isLoading } = useSelector((state: RootState) => state.validate)

  const toggle = () => setIsModal(!isModal)

  useEffect(() => {
    let localLanguage = localStorage.getItem('language')
    if (!localLanguage) {
      localStorage.setItem('language', 'INA')
      setLanguage('INA')
      i18n.changeLanguage('INA')
    } else {
      setLanguage(localLanguage)
      i18n.changeLanguage(localLanguage)
    }
  }, [language])

  useEffect(() => {
    let dayNow = new Date().getDate() + 1
    let expDay = dayNow < 10 ? '0' + dayNow : dayNow

    let time: any = moment()
      .utcOffset('07:00')
      .format(`YYYY-MM-${expDay}THH:mm:ss`)

    if (exp === undefined) {
      setExp(time)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      {isModal && (
        <div
          className="order-modal"
          style={{ position: 'relative', left: '0px' }}
        >
          <div className="order-modal-dialog bottom-padding">
            <div className="order-modal-content">
              <div className="order-modal-body">
                <div onClick={() => toggle()} className="order-box">
                  {isLoading ? <Loader /> : <CardHeader isOpen exp={exp} />}
                </div>
                <div className="content-table scroll-y order-modal-wrapper">
                  {isLoading ? <LoaderDetail /> : <Detail />}
                </div>
              </div>
            </div>
          </div>
          <div className="order-modal-backdrop"></div>
        </div>
      )}

      <nav className="header" id="header">
        <div className="title-bar">
          <div className="logo-store">
            <img src={logo} alt="merchant-logo" />
          </div>
          <div className="header-drop" onClick={() => setIsOpen(!isOpen)}>
            {language}
            <FontAwesomeIcon
              className="ml-2"
              icon={isOpen ? faChevronUp : faChevronDown}
            />
          </div>
        </div>
        {!not && (
          <div className="order-header">
            <div className="order-box" onClick={() => toggle()}>
              {isLoading ? <Loader /> : <CardHeader exp={exp} />}
            </div>
          </div>
        )}
      </nav>
    </>
  )
}

export default Header
