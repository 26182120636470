const addStep = (children: string) => ({ children });

const ind0 = [
    addStep(`Masukkan kartu debit/ATM kamu`),
    addStep(`Masukkan PIN > Pilih menu Bayar/Beli.`),
    addStep(`Pilih menu Lainnya > Multi Payment.`),
    addStep(`Pilih penyedia jasa OY! Indonesia Masukkan kode biller 89325, lalu pilih Benar.`),
    addStep(`Masukkan nomor Virtual Account Mandiri OY! kamu.`),
    addStep(`Masukkan jumlah nominal.`),
    addStep(`Ikuti petunjuk hingga transaksi selesai.`),
]

const ind1 = [
    addStep(`Masuk ke aplikasi Mandiri Online.`),
    addStep(`Pilih menu Pembayaran > Buat Pembayaran Baru.`),
    addStep(`Pilih menu Multi Payment > Pilih jasa OY! Indonesia.`),
    addStep(`Masukkan nomor VA (klik Tambah Sebagai Nomor Baru)`),
    addStep(`Masukkan nominal.`),
    addStep(`Konfirmasi pembayaran Anda lalu masukkan PIN mandiri Online Anda.`),
    addStep(`Transaksi selesai, simpan bukti pembayaran Anda.`),
]

const eng0 = [
    addStep(`Enter your Debit/ATM card.`),
    addStep(`Enter the PIN > Select the Pay/Buy menu.`),
    addStep(`Select More menu > Multi Payment.`),
    addStep(`Choose an OY! Indonesia Enter biller code 89325, then select Correct.`),
    addStep(`Enter your Mandiri OY! Virtual Account number.`),
    addStep(`Enter the nominal amount.`),
    addStep(`Follow the instructions until the transaction is complete.`),
]

const eng1 = [
    addStep(`Login to Mandiri Online application.`),
    addStep(`Select Payment menu > Create New Payment.`),
    addStep(`Select  Multi Payment menu > Choose OY! Indonesia.`),
    addStep(`Enter the VA number (click Add As New Number)`),
    addStep(`Enter a nominal.`),
    addStep(`Confirm your payment then enter your Mandiri Online PIN.`),
    addStep(`Transaction is complete, save your proof of payment.`),
]


export const ListPaymentStepInd = [
    ind0,ind1
]
export const ListPaymentStepEng = [
    eng0,eng1
]
