import Yukk from 'assets/e-wallet-yukk.png'

import EmoneyLayout from 'layout/eMoneyLayout'

const YukkPayment = ({
  showRekening,
  redirectFinish,
  submitPayment,
}: {
  showRekening: any
  redirectFinish: any
  submitPayment: any
}) => {
  return (
    <EmoneyLayout
      children={undefined}
      title="Yukk"
      {...{ showRekening, redirectFinish, submitPayment }}
      img={Yukk}
      styles={{ width: 60, height: 50 }}
    ></EmoneyLayout>
  )
}

export default YukkPayment
