import Cookies from 'js-cookie'

export const setSessionStorage = (key, value) => {
  sessionStorage.setItem(key, value)
}

export const removeSessionStorage = (key) => {
  sessionStorage.removeItem(key)
}

export const getSessionStorage = (key) => {
  return sessionStorage.getItem(key)
}

export const setCookie = (key, value) => {
  Cookies.set(key, value)
}

export const removeCookie = (key) => {
  Cookies.remove(key)
}

export const getCookie = (key) => {
  return Cookies.get(key)
}
