const addStep = (children: string) => ({ children });

const ind0 = [
  addStep(`Masukkan kartu ATM > PIN`),
  addStep(`Pilih menu <strong>Transaksi Lain > Pembayaran.</strong>`),
  addStep(`Pilih menu <strong>Lainnya > BRIVA.</strong>`),
  addStep(`Masukkan <strong>nomor Virtual Account BRI OY</strong> kamu.`),
  addStep(`Pilih <strong>Ya</strong>, untuk memproses Pembayaran.`),
];

const ind1 = [
  addStep(`Login ke BRI Mobile dan pilih Mobile Banking BRI`),
  addStep(`Pilih menu info > BRIVA`),
  addStep(`Masukkan <strong>nomor Virtual Account BRI OY!</strong> kamu`),
  addStep(`Masukkan <strong>PIN mobile/ SMS Banking BRI.</strong>`),
  addStep(`Kamu akan mendapatkan notifikasi pembayaran.`),
];

const ind2 = [
  addStep(`You will get a payment notification.`),
  addStep(`Pilih menu <strong>Pembayaran > BRIVA</strong>`),
  addStep(`Masukkan <strong>nomor Virtual Account BRI OY! kamu.</strong>`),
  addStep(`Masukkan <strong>password Internet Banking BRI.</strong>`),
  addStep(`Masukkan <strong>mToken Internet Banking BRI.</strong>`),
  addStep(`Kamu akan mendapatkan notifikasi pembayaran.`),
];
const eng0 = [
  addStep(`Select <strong>Other Transactions menu > Payments.</strong>`),
  addStep(`Select <strong> More menu > BRIVA. </strong>`),
  addStep(`Enter your <strong> BRI OY Virtual Account number. </strong>`),
  addStep(`Select <strong>Yes</strong>, to process Payment.`),
];

const eng1 = [
  addStep(`Login to the BRI Mobile and select Mobile Banking BRI.`),
  addStep(`Select menu <strong>info > BRIVA</strong>`),
  addStep(`Enter your <strong> BRI OY Virtual Account number. </strong>`),
  addStep(`Enter your <strong> BRI mobile/SMS Banking PIN. </strong>`),
  addStep(`You will get a payment notification.`),
];

const eng2 = [
  addStep(`Login to BRI Internet Banking.`),
  addStep(`Select the <strong>Payment menu > BRIVA</strong>`),
  addStep(`Enter your <strong>BRI OY! Virtual Account number.</strong>`),
  addStep(`Enter the <strong>BRI Internet Banking password.</strong>`),
  addStep(`Enter <strong>BRI Internet Banking mToken.</strong>`),
  addStep(`You will get a payment notification.`),
];

export const ListPaymentStepInd = [ind0, ind1, ind2];
export const ListPaymentStepEng = [eng0, eng1, eng2];
