import { Dispatch } from 'react'
import { RootState } from 'stores'
import {
  CustomerTypes,
  ICustomerState,
  ISetCustomerData,
} from 'stores/types/customerTypes'
import { apiPostToken } from './api'
import { errorHandleAPI, swalOptions } from 'stores/actions'
import { setLoading } from './validate'
import { getChannelMenu } from 'stores/actions'
import {
  IMerchantData,
  ISetMerchantData,
  MerchantTypes,
} from 'stores/types/merchantTypes'
import {
  ISetTransactionPrice,
  ITransactionPrices,
  ITransactionState,
  TransactionTypes,
} from 'stores/types/transactionTypes'

import swal from 'sweetalert'
import constant from 'config/constant'

export const postCustomer = (orderNumber: string | Blob) => (
  dispatch: any,
  getState: () => RootState,
) => {
  let { name, phone, email } = getState().customer
  let body = {
    OrderNumber: orderNumber,
    PayerName: name,
    PayerPhone: phone,
    PayerEmail: email,
  }
  let { signature } = getState().validate
  apiPostToken(constant.URL_PAY_NOW, body, signature)
    .then((res: any) => {
      if (res.status === 200) {
        dispatch(getChannelMenu(orderNumber))
      } else {
        dispatch(setLoading(false))
        swal(swalOptions(res.message))
      }
    })
    .catch(function (error) {
      dispatch(setLoading(false))
      let isTrace = dispatch(errorHandleAPI(error))
      if (isTrace === false) {
        if (error.response) {
          swal(swalOptions(error.response.data.message))
        }
      }
    })
}

export const getData = (orderNumber: string | Blob) => (
  dispatch: any,
  getState: () => RootState,
) => {
  let body = {
    OrderNumber: orderNumber,
  }

  let signature = getState().validate.signature

  apiPostToken(constant.URL_INITIATE_WEB, body, signature)
    .then((res: any) => {
      if (res && res.data.status_code === 200) {
        let data = res.data.data

        let merchanData: IMerchantData = {
          id: data.merchant.id,
          name: data.merchant.name,
          orderID: data.number,
          referenceNumber: data.reference_number,
          transID: data.id,
          code: data.merchant.code,
        }

        let prices: ITransactionPrices = {
          price: data.initial_amount,
          priceCharge: 0,
          chargeSystem: 0,
          currency: '',
          totalAmount: 0,
          totalProduct: 0,
          serviceFee: 0,
        }

        let orderDetail: ITransactionState['orderDetail'] = data.description

        let contact = data?.user?.contact?.split(';') || ''

        let dataCustomer: ICustomerState = {
          name: data.user.name || '',
          phone: contact[0] || '',
          email: contact[1] || '',
          condition: {
            isDisabled:
              data.user?.name && contact[0] && contact[1] ? true : false,
            isDisabledName: data?.user?.name ? true : false,
            isDisabledPhone: contact[0] ? true : false,
            isDisabledEmail: contact[1] ? true : false,
          },
        }

        dispatch(submitFormCustomer(dataCustomer))
        dispatch(postCustomer(orderNumber))
        dispatch(setCustomer(dataCustomer))
        dispatch(setMerchanData(merchanData))
        dispatch(setOrderDetail(orderDetail))
        dispatch(setTransactionPrice(prices))
      }
    })
    .catch(function (error) {
      dispatch(setLoading(false))
      let isTrace = dispatch(errorHandleAPI(error))
      if (isTrace === false) {
        if (error.response) {
          swal(swalOptions(error.response.data.message))
        }
      }
    })
}

export const setCustomer = (data: ICustomerState) => (
  dispatch: Dispatch<ISetCustomerData>,
) => {
  dispatch({
    type: CustomerTypes.SET_CUSTOMER_DATA,
    payload: data,
  })
}

export const submitFormCustomer = (data: ICustomerState) => (
  dispatch: (dispatch: any) => void,
) => {
  dispatch({
    type: CustomerTypes.SET_CUSTOMER_DATA,
    payload: data,
  })
}

export const setOrderDetail = (data: ITransactionState['orderDetail']) => (
  dispatch: (dispatch: any) => void,
) => {
  dispatch({
    type: TransactionTypes.SET_TRANSACTION_ORDER_DETAIL,
    payload: {
      orderDetail: data,
    },
  })
}

export const setMerchanData = (data: IMerchantData) => (
  dispatch: Dispatch<ISetMerchantData>,
) => {
  dispatch({
    type: MerchantTypes.SET_MERCHANT_DATA,
    payload: {
      merchantData: data,
    },
  })
}

export const setTransactionPrice = (data: ITransactionPrices) => (
  dispatch: Dispatch<ISetTransactionPrice>,
) => {
  dispatch({
    type: TransactionTypes.SET_TRANSACTION_PRICES,
    payload: {
      prices: data,
    },
  })
}
