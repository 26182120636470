let APP_CONFIG = {
  API_ROOT: process.env.REACT_APP_API_ROOT,
  ESPAY_URL: process.env.REACT_APP_ESPAY_URL,
  CC_PUBLIC_KEY: process.env.REACT_APP_CC_PUBLIC_KEY,
  CC_URL: process.env.REACT_APP_CC_URL,
  KORALANTAS_CODE: process.env.REACT_APP_KORALANTAS_CODE,
  OTA_CODE: process.env.REACT_APP_OTA_CODE,
  INISA_CODE: process.env.REACT_APP_INISA_CODE,
};

export default APP_CONFIG;
