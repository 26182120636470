import { getSiganture, getCookie, generateXSRFToken } from './index'
import axiosBase from './axios'

export const headerToken = (data: string | undefined) => {
  let signature
  if (data) {
    signature = data
  } else {
    signature = getSiganture()
  }
  let header = {
    headers: {
      Accept: 'application/json',
      Signature: signature,
      'content-type': 'application/json',
      'X-XSRF-TOKEN': getCookie('xsrf-token'),
    },
  }
  return header
}

export const apiPostToken = async (
  url: string,
  body: any,
  signature?: string,
) => {
  await generateXSRFToken()
  return new Promise((resolve, reject) => {
    axiosBase
      .post(url, body, headerToken(signature))
      .then((res) => {
        resolve(res)
      })
      .catch((error) => {
        reject(error)
      })
  })
}
