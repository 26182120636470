const addStep = (children: string) => ({ children });

const ind0 = [
  addStep(`Masukkan kartu ATM dan PIN.`),
  addStep(`Pilih <strong>Menu lain > Transfer</strong>.`),
  addStep(`Pilih jenis rekening asal dan pilih Virtual Account Billing.`),
  addStep(
    `Masukkan <strong>nomor Virtual Account<?b> 880815409492 dan pilih <strong>Benar</strong>.`
  ),
  addStep(
    `Periksa jumlah tagihan yang harus dibayarkan, jika sesuai klik <strong>Ya</strong>.`
  ),
  addStep(`Transaksi selesai.`),
];

const ind1 = [
  addStep(`Login ke akun <strong>BNI iBank.</strong>`),
  addStep(`Pilih <strong>Transfer > Virtual Account Billing.</strong>`),
  addStep(`Masukkan nomor <strong>Virtual Account</strong> 88081540949 `),
  addStep(`Periksa jumlah tagihan yang harus dibayarkan, jika sesuai klik Ya.`),
  addStep(`Transaksi selesai.`),
];

const ind2 = [
  addStep(`Login ke akun BNI Mobile kamu.`),
  addStep(`Pilih menu Transfer > Virtual Account Billing.`),
  addStep(
    `Pilih menu <strong> input baru > </strong> Masukkan <strong> nomor Virtual Account. </strong>`
  ),
  addStep(
    `Periksa jumlah tagihan yang harus dibayarkan, jika sesuai klik <strong>Ya.</strong>`
  ),
  addStep(`Transaksi selesai.`),
];
const eng0 = [
  addStep(`Enter the ATM card and PIN.`),
  addStep(`Select <strong> Other menu > Transfer. </strong>.`),
  addStep(
    `Select the original account type and select Virtual Account Billing.`
  ),
  addStep(
    `Enter <strong>the Virtual Account number</strong> 880815409492 and select True.`
  ),
  addStep(`Check the amount of the bill to be paid, if appropriate click Yes.`),
  addStep(`Transaction completed.`),
];

const eng1 = [
  addStep(`Login to your <strong>BNI iBank account.</strong>`),
  addStep(`Select <strong>Transfer > Virtual Account Billing.</strong>`),
  addStep(`Enter <strong> the Virtual Account number </strong> 880815409492.`),
  addStep(
    `Check the amount of the bill to be paid, if appropriate click <strong>Yes.</strong>`
  ),
  addStep(`Transaction completed.`),
];

const eng2 = [
  addStep(`Login to your BNI Mobile account.`),
  addStep(
    `Select the <strong>Transfer menu > Virtual Account Billing.</strong>`
  ),
  addStep(
    `Select the <strong>new input menu > </strong> Enter the <strong>Virtual Account number.</strong>`
  ),
  addStep(
    `Check the amount of the bill to be paid, if appropriate click <strong>Yes</strong>`
  ),
  addStep(`Transaction completed.`),
];

export const ListPaymentStepInd = [ind0, ind1, ind2];
export const ListPaymentStepEng = [eng0, eng1, eng2];
