import LinkAja from 'assets/e-wallet-link-aja.png'
import EmoneyLayout from 'layout/eMoneyLayout'

const LinkAjaPayment = ({
  showRekening,
  redirectFinish,
  submitPayment,
}: {
  showRekening: any
  redirectFinish: any
  submitPayment: any
}) => {
  return (
    <EmoneyLayout
      children={undefined}
      title="Link Aja"
      img={LinkAja}
      styles={undefined}
      {...{ showRekening, redirectFinish, submitPayment }}
    ></EmoneyLayout>
  )
}

export default LinkAjaPayment
