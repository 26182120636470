import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import Qrcode from 'qrcode'
import { ListPaymentStepEng, ListPaymentStepInd } from './ListPaymentStep'

import qrLogo from 'assets/e-wallet-qris.png'
import CardContent from 'layout/cardContent'
import { CardListPaymentStep } from 'components/CardListPaymentStep'
import { RootState } from 'stores'
import { useSelector } from 'react-redux'
import Footer from 'components/Footer'

const QrisPayment = ({ redirectFinish }: { redirectFinish: any }) => {
  const [qrCode, setQrCode] = useState('')
  // const [expiredDate, setExpiredDate] = useState(moment());
  const { i18n, t } = useTranslation()

  const { qris } = useSelector((state: RootState) => state.rekening)
  const { showRekening } = useSelector((state: RootState) => state.rekening)

  useEffect(() => {
    if (qris.value !== '') {
      generateQrcode(qris.value)
      // setExpiredDate(moment().add(1, 'days'))
    }
  }, [qris])

  const generateQrcode = (data: string) => {
    Qrcode.toDataURL(data, (err: any, url: string) => {
      if (err) {
        console.log(err, 'error')
      } else {
        setQrCode(url)
      }
    })
  }

  return (
    <React.Fragment>
      {showRekening && (
        <CardContent>
          <div className="p-2 d-flex justify-content-between align-items-center">
            <b>QRIS</b>
            <img src={qrLogo} alt="log-qris" />
          </div>
          <div className="d-flex w-100 justify-content-center align-items-center p-3 flex-column">
            <img src={qrCode} style={{ width: 197 }} alt="QR CODE" />
            <div style={{ color: '#231f2059', fontSize: 12 }}>
              Powered by <img src={qrLogo} alt="log-qris" />
            </div>
          </div>
        </CardContent>
      )}
      <CardContent>
        <div style={{ margin: 10 }}>
          <b>{t('CaraMembayar')}</b>
          <hr />
        </div>
        <div>
          <CardListPaymentStep
            items={
              i18n.language === 'ENG'
                ? ListPaymentStepEng[0]
                : ListPaymentStepInd[0]
            }
          />
        </div>
      </CardContent>
      <div className="mb-3"></div>
      <Footer textFooter="REDIRECT" submit={redirectFinish} />
    </React.Fragment>
  )
}

export default QrisPayment
