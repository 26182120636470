export enum CustomerTypes {
  SET_CUSTOMER_DATA = 'SET_CUSTOMER_DATA',
  SET_CUSTOMER_MESSAGE = 'SET_CUSTOMER_MESSAGE',
  SET_CUSTOMER_CONDITION = 'SET_CUSTOMER_CONDITION',
  SET_CREDIT_CARD_DATA = 'SET_CREDIT_CARD_DATA',
}

export interface ICustomerState {
  name: string
  phone: string
  email: string
  amount?: string
  condition: ICustomerCondition
}

interface ICustomerCondition {
  isDisabled: boolean
  isDisabledName: boolean
  isDisabledPhone: boolean
  isDisabledEmail: boolean
}

export interface ISetCustomerData {
  type: CustomerTypes.SET_CUSTOMER_DATA
  payload: ICustomerState
}

export type ICustomer = ISetCustomerData
