/* eslint-disable @typescript-eslint/no-unused-vars */
import { createStore, applyMiddleware } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import logger from 'redux-logger'
import thunk from 'redux-thunk'
import rootReducer from './reducers/index'

import { ICustomerState } from './types/customerTypes'
import { IMerchantState } from './types/merchantTypes'
import { IPaymentMethodState } from './types/paymentMethodTypes'
import { IRekeningState } from './types/rekeningTypes'
import { ITransactionState } from './types/transactionTypes'
import { IValidateState } from './types/validateTypes'

let middleware: any[] = [thunk]
if (process.env.NODE_ENV === 'development') {
  middleware = [...middleware, logger]
}

export default function configStore() {
  const store = createStore(
    rootReducer,
    composeWithDevTools(applyMiddleware(thunk)),
  )
  // const persistor = persistStore(store)
  return { store }
}

export interface RootState {
  customer: ICustomerState
  merchant: IMerchantState
  paymentMethod: IPaymentMethodState
  transaction: ITransactionState
  validate: IValidateState
  rekening: IRekeningState
}
