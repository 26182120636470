import {
  ICustomerState,
  ICustomer,
  CustomerTypes,
} from '../types/customerTypes'

const initialState = {
  name: '',
  phone: '',
  email: '',
  condition: {
    isDisabled: false,
    isDisabledName: false,
    isDisabledPhone: false,
    isDisabledEmail: false,
  },
}

const Customer = (
  state: ICustomerState = initialState,
  { type, payload }: ICustomer,
) => {
  switch (type) {
    case CustomerTypes.SET_CUSTOMER_DATA:
      return { ...state, ...payload }
    default:
      return state
  }
}

export default Customer
