import React from 'react'
import ContentLoader from 'react-content-loader'

const MyLoader = (props) => (
  <ContentLoader
    speed={2}
    width={300}
    height={130}
    viewBox="0 0 388 130"
    backgroundColor="#e2dfdf"
    foregroundColor="#fdfcfc"
  >
    <rect x="18" y="22" rx="0" ry="0" width="100" height="16" />
    <rect x="18" y="62" rx="0" ry="0" width="180" height="16" />
    <rect x="18" y="92" rx="0" ry="0" width="180" height="16" />
    <rect x="260" y="92" rx="0" ry="0" width="128" height="16" />
    <rect x="260" y="62" rx="0" ry="0" width="128" height="16" />
  </ContentLoader>
)

export default MyLoader
