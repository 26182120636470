const addStep = (children: string) => ({ children });

const ind0 = [
  addStep(`Masukkan kartu ATM dan PIN.`),
  addStep(`Pilih <strong>Menu lain > Transfer</strong>.`),
  addStep(`Pilih jenis rekening asal dan pilih Virtual Account Billing.`),
  addStep(
    `Masukkan <strong>nomor Virtual Account<?b> 880815409492 dan pilih <strong>Benar</strong>.`
  ),
  addStep(
    `Periksa jumlah tagihan yang harus dibayarkan, jika sesuai klik <strong>Ya</strong>.`
  ),
  addStep(`Transaksi selesai.`),
];

const ind1 = [
  addStep(`Login ke akun <strong>BNI iBank.</strong>`),
  addStep(`Pilih <strong>Transfer > Virtual Account Billing.</strong>`),
  addStep(`Masukkan nomor <strong>Virtual Account</strong> 88081540949 `),
  addStep(`Periksa jumlah tagihan yang harus dibayarkan, jika sesuai klik Ya.`),
  addStep(`Transaksi selesai.`),
];

const ind2 = [
  addStep(`Login ke akun BNI Mobile kamu.`),
  addStep(`Pilih menu Transfer > Virtual Account Billing.`),
  addStep(
    `Pilih menu <strong> input baru > </strong> Masukkan <strong> nomor Virtual Account. </strong>`
  ),
  addStep(
    `Periksa jumlah tagihan yang harus dibayarkan, jika sesuai klik <strong>Ya.</strong>`
  ),
  addStep(`Transaksi selesai.`),
];
const eng0 = [
  addStep(`Enter ATM card and PIN`),
  addStep(
    `Select <strong>More Transactions > Transfer > Other Banks.</strong>`
  ),
  addStep(
    `Enter <strong> the bank code </strong>  and  <strong>Virtual Account number.</strong>`
  ),
  addStep(`Enter the amount transferred and select correct.`),
  addStep(
    `Periksa jumlah tagihan yang harus dibayarkan, jika sesuai klik <strong>Ya</strong>.`
  ),
  addStep(`Transaction confirmation.`),
];

const eng1 = [addStep(`Login to Bank NTT`)];

const eng2 = [
  addStep(`Login to Bank NTT`),
  addStep(`Select <strong>the Payment menu > Virtual Account.</strong>`),
  addStep(`Enter the Virtual Account.`),
  addStep(`Enter the Amount.`),
  addStep(`Transaction complete.`),
];

export const ListPaymentStepInd = [ind0, ind1, ind2];
export const ListPaymentStepEng = [eng0, eng1, eng2];
