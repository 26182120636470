import {
  IMerchantState,
  IMerchant,
  MerchantTypes,
} from '../types/merchantTypes'

const initialState = {
  merchantTheme: {
    display_name: '',
    logo: '',
    language: '',
    redirectUrl: '/',
  },
  merchantData: {
    id: '',
    name: '',
    orderID: '',
    referenceNumber: '',
    transID: '',
    code: '',
  },
}

const Merchant = (
  state: IMerchantState = initialState,
  { type, payload }: IMerchant,
) => {
  switch (type) {
    case MerchantTypes.SET_MERCHANT_DATA:
      return { ...state, ...payload }
    case MerchantTypes.SET_MERCHANT_THEME:
      return { ...state, ...payload }
    default:
      return state
  }
}

export default Merchant
