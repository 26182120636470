import React, { useState } from 'react'
import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { UncontrolledCollapse } from 'reactstrap'
import { ReactI18NextChild, useTranslation } from 'react-i18next'
import { CardListPaymentStep } from 'components/CardListPaymentStep'

const BodyPaymentPage = ({
  tabsList,
  selectedTab,
  setSelectedTab,
  ListPaymentStep,
}: IBodyPaymentPage) => {
  const [isIcon, setIsIcon] = useState(new Array(tabsList.length).fill(false))

  const handleIcon = (position: number) => {
    const updatedCheckedState = isIcon.map((item, index) =>
      index === position ? !item : item,
    )
    setIsIcon(updatedCheckedState)
  }

  const { t }: { t: any } = useTranslation()

  return (
    <div className="stepPay">
      <b className="ml-2">{t('CaraMembayar')}</b>
      {tabsList.map(
        (
          tab: {
            id: number
            text:
              | string
              | number
              | boolean
              | React.ReactElement<
                  any,
                  string | React.JSXElementConstructor<any>
                >
              | React.ReactFragment
              | React.ReactPortal
              | Iterable<ReactI18NextChild>
              | null
              | undefined
          },
          index: React.Key | null | undefined,
        ) => (
          <React.Fragment key={index}>
            <div
              className="d-flex w-100 border-bottom justify-content-between p-2"
              // for mobile
              onTouchStart={() => {
                handleIcon(tab.id)
                setSelectedTab(tab.id)
              }}
              // for dekstop
              onClick={() => {
                handleIcon(tab.id)
                setSelectedTab(tab.id)
              }}
              id={`toggler${tab.id}`}
              style={{ cursor: 'pointer' }}
            >
              <b style={{ fontSize: 14 }}>{t(tab.text)}</b>
              <div className="icon-content">
                <FontAwesomeIcon
                  icon={isIcon[tab.id] ? faAngleUp : faAngleDown}
                />
              </div>
            </div>
            {selectedTab >= 0 && (
              <UncontrolledCollapse toggler={`#toggler${tab.id}`}>
                <CardListPaymentStep items={ListPaymentStep[tab.id]} />
              </UncontrolledCollapse>
            )}
          </React.Fragment>
        ),
      )}
    </div>
  )
}

export default BodyPaymentPage
