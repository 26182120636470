import { setValidate } from './validate'
import swal from 'sweetalert'
import constant from 'config/constant'
import axios from 'axios'
import { setCookie } from './index'
import { captureMessage } from '@sentry/minimal'
import { setContext } from '@sentry/react'

export const getSiganture = () => {
  let url = window.location.href
  let isPaymentLink = url.includes('trxPaymentlinkCodeGroup')
  let signature = url.split('?s=')[1]
  if (isPaymentLink) {
    let signaturePaymentLink = signature.split('&trxPaymentlinkCodeGroup')[0]
    return signaturePaymentLink
  } else {
    return signature
  }
}

export const errorHandleAPI = (error: { response: any }) => (
  dispatch: (arg0: any) => void,
) => {
  let status = error.response.status >= 400 && error.response.status < 500

  if (error.response) {
    if (!status && !error.response.data) {
      captureMessage(`${error}`)
    } else if (!status && error.response.data) {
      captureMessage(`${error}`)
      setContext(`error`, {
        data: error.response.data,
      })
    }

    let messageIsValid = ''
    if (error.response.status === 401) {
      messageIsValid = 'Batas Waktu Transaksi Anda Telah Berakhir'
    }
    if (error.response.status === 403) {
      messageIsValid = 'Mohon maaf tansaksi anda tidak dapat diproses.'
    }
    if (error.response.status === 400) {
      messageIsValid = 'Mohon maaf tansaksi anda tidak dapat diproses.'
    }
    if (error.response.status === 500) {
      messageIsValid = 'Server Error'
    }
    if (messageIsValid !== '') {
      dispatch(setValidate(messageIsValid))
      return true
    } else {
      return false
    }
  } else {
    swal(swalOptions('ERROR CONNECTION'))
    return true
  }
}

export const swalOptions = (text: string) => {
  return {
    text,
    icon: 'error',
    timer: 3000,
    button: false,
  }
}

export const generateXSRFToken = async () => {
  const url = constant.URL_MASTER_PATH + 'v2/tok'
  let response = await axios.post(url)
  setCookie('xsrf-token', response?.data?.data?._tok)
}
