import { RootState } from 'stores'
import { apiPostToken } from './api'
import { setLoading, errorHandleAPI, swalOptions } from 'stores/actions'
import swal from 'sweetalert'
import {
  IPaymentMethodState,
  ISetPaymentMethod,
  PaymentMethodTypes,
} from 'stores/types/paymentMethodTypes'
import constant from 'config/constant'
import { Dispatch } from 'react'

export const getChannelMenu = (orderNumber: string | Blob) => (
  dispatch: any,
  getState: () => RootState,
) => {
  let signature = getState().validate.signature

  let body = {
    OrderNumber: orderNumber,
  }

  apiPostToken(constant.URL_GET_CHANNEL, body, signature)
    .then((res: any) => {
      if (res.status === 200) {
        let paymentMethodList = getState().paymentMethod.paymentMethodList
        paymentMethodList = res.data.data
        dispatch({
          type: PaymentMethodTypes.SET_PAYMENTMETHOD_LIST,
          payload: { paymentMethodList },
        })
        dispatch(setLoading(false))
      } else {
        dispatch(setLoading(false))
        swal(swalOptions(res.message))
      }
    })
    .catch(function (error) {
      dispatch(setLoading(false))
      let isTrace = dispatch(errorHandleAPI(error))
      if (isTrace === false) {
        if (error.response) {
          swal(swalOptions(error.response.data.message))
        }
      }
    })
}

export const setPaymentMethod = (
  data: IPaymentMethodState['paymentMethod'],
) => (dispatch: Dispatch<ISetPaymentMethod>) => {
  dispatch({
    type: PaymentMethodTypes.SET_PAYMENTMETHOD,
    payload: {
      paymentMethod: data,
      isPaymentSubmit: true,
    },
  })
}

export const clearPaymentMethod = () => ({
  type: PaymentMethodTypes.CLEAR_PAYMENT_METHOD,
  payload: {
    paymentMethod: {
      id: 0,
      name: '',
      page_id: 0,
      picture: {
        url: '',
      },
    },
    isPaymentSubmit: false,
  },
})
export const clearPaymentMethodList = () => ({
  type: PaymentMethodTypes.CLEAR_PAYMENT_METHODLIST,
  payload: {
    paymentMethodList: {},
  },
})
