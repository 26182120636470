export enum ValidateTypes {
  SET_VALIDATE = 'SET_VALIDATE',
  SET_VALIDATE_WEB = 'SET_VALIDATE_WEB',
  SET_LOADING = 'SET_LOADING',
  SET_IFRAME = 'SET_IFRAME',
  SET_STOP_SCHEDULER = 'SET_STOP_SCHEDULER',
  SET_BLASTING = 'SET_BLASTING',
  SET_SCHEDULER = 'SET_SCHEDULER',
  SET_SIGNATURE = 'SET_SIGNATURE',
  SET_REDIRECT_URL = 'SET_REDIRECT_URL',
  SET_CC_FRAME = 'SET_CC_FRAME',
  SET_ORDER_NUMBER = 'SET_ORDER_NUMBER',
}

export interface IValidateState {
  orderNumber: string
  isValidate: boolean
  messageIsValid: string
  isLoading: boolean
  isOnline: boolean
  emailBlasting: string
  isDone: boolean
  urlIframeCC: string
  klikPayVal: any
  klikPayStatus: boolean
  klikPayData: any
  clientKey: string
  stopScheduler: boolean
  isOpenIframe: boolean
  callbackIframe: any
  validateWeb: boolean
  signature: string
  redirectUrl: string
}

export interface ISetValidate {
  type: ValidateTypes.SET_VALIDATE
  payload: {
    isLoading: IValidateState['isLoading']
    messageIsValid: IValidateState['messageIsValid']
    isValidate: IValidateState['isValidate']
  }
}
export interface ISetOrderNumber {
  type: ValidateTypes.SET_ORDER_NUMBER
  payload: {
    orderNumber: IValidateState['orderNumber']
  }
}

export interface ISetRedirectUrl {
  type: ValidateTypes.SET_REDIRECT_URL
  payload: {
    redirectUrl: IValidateState['redirectUrl']
  }
}

export interface ISetLoading {
  type: ValidateTypes.SET_LOADING
  payload: {
    isLoading: IValidateState['isLoading']
  }
}

export interface ISetValidateWeb {
  type: ValidateTypes.SET_VALIDATE_WEB
  payload: {
    validateWeb: IValidateState['validateWeb']
  }
}

export interface ISetSignature {
  type: ValidateTypes.SET_SIGNATURE
  payload: {
    signature: IValidateState['signature']
  }
}

export interface ISetSignature {
  type: ValidateTypes.SET_SIGNATURE
  payload: {
    signature: IValidateState['signature']
  }
}
export interface ISetIframeCC {
  type: ValidateTypes.SET_CC_FRAME
  payload: {
    urlIframeCC: IValidateState['urlIframeCC']
  }
}

export type IValidate =
  | ISetValidate
  | ISetLoading
  | ISetValidateWeb
  | ISetSignature
  | ISetRedirectUrl
  | ISetIframeCC
  | ISetOrderNumber
