const addStep = (children) => ({ children })

const ind0 = [
  addStep('Buka aplikasi yang support dengan pembayaranan QRIS.'),
  addStep('Scan code QR yang ada di layar kamu.'),
  addStep('Periksa detail pembayaran, jika sesuai pilih bayar.')
]

const eng0 = [
  addStep('Open an application that supports QRIS payments.'),
  addStep('Scan the QR code on your screen.'),
  addStep('Check payment details, if appropriate select pay')
]

const ListPaymentStep = [
  ind0
]

export const ListPaymentStepInd = [
  ind0
]
export const ListPaymentStepEng = [
  eng0
]

export default ListPaymentStep
