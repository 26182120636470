export enum RekeningTypes {
  SET_PAYMENTVA = 'SET_PAYMENTVA',
  SET_SIGNATUREVA = 'SET_SIGNATUREVA',
  SET_TICKETID = 'SET_TICKETID',
  SET_CREDIT_CARD_DATA = 'SET_CREDIT_CARD_DATA',
  SET_QRIS = 'SET_QRIS',
}

export interface IRekeningState {
  bankVA: IbankVa
  signatureVa: string
  ticketId: string
  showRekening: boolean
  creditCard: ICreditCard
  qris: IQris
}

interface IQris {
  value: string
  expire: string
}

interface ICreditCard {
  account_number: string
  exp_month: string
  exp_year: string
  cvn: string
}

export interface IbankVa {
  nomorRekening: string
  expiredDate: string
}

export interface ISetPaymentVa {
  type: RekeningTypes.SET_PAYMENTVA
  payload: {
    bankVA: IbankVa
    showRekening: IRekeningState['showRekening']
  }
}

export interface ISetSignatureVa {
  type: RekeningTypes.SET_SIGNATUREVA
  payload: {
    signatureVa: IRekeningState['signatureVa']
    ticketId: IRekeningState['ticketId']
  }
}

export interface ISetCreditCard {
  type: RekeningTypes.SET_CREDIT_CARD_DATA
  payload: {
    creditCard: ICreditCard
  }
}

export interface ISetQris {
  type: RekeningTypes.SET_QRIS
  payload: {
    qris: {
      value: IQris['value']
      expire: IQris['expire']
    }
    showRekening: IRekeningState['showRekening']
  }
}

export type IRekening =
  | ISetSignatureVa
  | ISetPaymentVa
  | ISetCreditCard
  | ISetQris
