import React from 'react'
import ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'
import configureStore from 'stores'
import App from './App'
import reportWebVitals from './reportWebVitals'
import CacheBuster from 'react-cache-buster'

import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'

const { store } = configureStore()

Sentry.init({
  dsn: 'https://3c7bc84b19d148f68a0b21b95b1ac350@sentry01.loyalto.id/23',
  integrations: [new BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
})

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <CacheBuster
    currentVersion={'1.0.0'}
    isEnabled={true} //If false, the library is disabled.
    isVerboseMode={false} //If true, the library writes verbose logs to console.
    loadingComponent={<div>Loading...</div>} //If not pass, nothing appears at the time of new version check.
  >
    <Provider store={store}>
      <App />
    </Provider>
  </CacheBuster>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
