export enum MerchantTypes {
  SET_MERCHANT_THEME = 'SET_MERCHANT_THEME',
  SET_MERCHANT_DATA = 'SET_MERCHANT_DATA',
}

export interface IMerchantState {
  merchantTheme: IMerchantTheme
  merchantData: IMerchantData
}

export interface IMerchantTheme {
  display_name: string
  logo: string
  language: string
  redirectUrl: string
}

export interface IMerchantData {
  id: string
  name: string
  orderID: string
  referenceNumber: string
  transID: string
  code: string
}

export interface ISetMerchantTheme {
  type: MerchantTypes.SET_MERCHANT_THEME
  payload: {
    merchantTheme: IMerchantTheme
  }
}

export interface ISetMerchantData {
  type: MerchantTypes.SET_MERCHANT_DATA
  payload: {
    merchantData: IMerchantData
  }
}

export type IMerchant = ISetMerchantData | ISetMerchantTheme
