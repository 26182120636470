import React from 'react'
import ContentLoader from 'react-content-loader'

const MyLoader = (props) => (
  <ContentLoader
    speed={2}
    width={420}
    height={160}
    viewBox="0 0 388 130"
    backgroundColor="#e2dfdf"
    foregroundColor="#fdfcfc"
  >
    <circle cx="30" cy="21" r="15" />
    <rect x="60" y="13" rx="0" ry="0" width="128" height="16" />z
    <rect x="18" y="72" rx="0" ry="0" width="128" height="16" />
    <rect x="18" y="102" rx="0" ry="0" width="128" height="16" />
  </ContentLoader>
)

export default MyLoader
