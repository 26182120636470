import React from 'react'

import { useTranslation, withTranslation } from 'react-i18next'

import swal from 'sweetalert'

import './style.scss'

const RekeningNumber = ({ vaDetail, logo, alt, title }: IRekeningNumber) => {
  const { t } = useTranslation()

  const copy = (id: string) => {
    let test: any = document.body
    var elm = document.getElementById(id)
    if (test.createTextRange) {
      var range = test.createTextRange()
      range.moveToElementText(elm)
      range.select()
      document.execCommand('Copy')
      let swalOptions = {
        text: 'Berhasil menyalin',
        icon: 'success',
        timer: 2000,
        button: false,
      }
      swal(swalOptions)
    } else if (window.getSelection) {
      var selection: any = window.getSelection()
      // eslint-disable-next-line @typescript-eslint/no-redeclare
      var range: any = document.createRange()
      range.selectNodeContents(elm)
      selection.removeAllRanges()
      selection.addRange(range)
      document.execCommand('Copy')
      let swalOPtions = {
        text: 'Berhasil menyalin',
        icon: 'success',
        timer: 2000,
        button: false,
      }
      swal(swalOPtions)
    } else {
      let swalOptions = {
        text: 'Gagal menyalin',
        icon: 'error',
        timer: 2000,
        button: false,
      }
      swal(swalOptions)
    }
  }

  return (
    <React.Fragment>
      <div className="paddingBody">
        <div className="titleVa">
          <strong>{title}</strong>
          <img
            style={{ width: 50, maxHeight: 25 }}
            src={logo || ''}
            alt={alt}
          />
        </div>
        <div>
          <div style={{ fontSize: 14 }} className="mt-2">
            Complete payment from {title} to the virtual account number below.
          </div>
        </div>

        <div className="mt-1">
          <div className="title-va">
            <div className="d-flex flex-column">
              <div style={{ fontSize: 14 }}>{t('virtualAccount')}</div>
              <div className="d-flex w-100  justify-content-between ">
                <b className="mt-1" id="va">
                  {vaDetail.nomorRekening}
                </b>
                <div
                  className="mt-1"
                  onClick={() => copy('va')}
                  style={{
                    color: '#5975be',
                    fontWeight: 'bold',
                    cursor: 'pointer',
                  }}
                >
                  <b style={{ fontSize: 12 }}>{t('salin')}</b>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default withTranslation()(RekeningNumber)
