const addStep = (children: string) => ({ children });

const ind0 = [
  addStep(`Masukkan kartu debit/ATM kamu.`),
  addStep(`Masukkan PIN`),
  addStep(`Pilih <strong>Transaksi Lainnya > Virtual Account.</strong>`),
  addStep(`Masukkan <strong>nomor Virtual Account Permata OY! kamu.</strong>`),
  addStep(`Masukkan jumlah nominal.`),
  addStep(`Ikuti petunjuk hingga transaksi selesai.`),
];

const ind1 = [
  addStep(`Pilih menu <strong>Pembayaran Tagihan > Virtual Account.</strong>`),
  addStep(
    `Daftarkan nomor Virtual Account jika belum terdaftar. Pilih <strong>Tagihan Anda > Daftar Tagihan Baru.</strong>`
  ),
  addStep(`Masukkan <strong> nomor Virtual Account Permata OY! kamu.</strong>`),
  addStep(
    `Masukkan <strong>response code yang dikirim ke nomor ponsel kamu</strong>`
  ),
  addStep(`Ikuti petunjuk hingga transaksi selesai.`),
];

const ind2 = [
  addStep(`Pilih menu Pembayaran.`),
  addStep(`Pilih menu <strong>Pembayaran Tagihan > Virtual Account.</strong>`),
  addStep(`Masukkan <strong>nomor Virtual Account Permata OY! kamu.</strong>`),
  addStep(`Masukkan response code yang dikirim ke nomor ponsel kamu.`),
  addStep(`Ikuti petunjuk hingga transaksi selesai.`),
];
const eng0 = [
  addStep(`Enter your <strong>Debit/ATM card.</strong>`),
  addStep(`Enter PIN`),
  addStep(`Select More <strong> Transactions > Virtual Account.</strong>`),
  addStep(`Enter your <strong>Permata OY! Virtual Account number.</strong>`),
  addStep(`Enter the <strong>nominal amount.</strong>`),
  addStep(`Follow the instructions until the transaction is complete.`),
];

const eng1 = [
  addStep(`Select the <strong>Bill Payment menu > Virtual Account.</strong>`),
  addStep(
    `Register a <strong>Virtual Account number<strong> if it is not already registered. Select Your <strong>Billing > New Billing List.</strong>`
  ),
  addStep(`Enter your <strong>Permata OY! Virtual Account number.</strong>`),
  addStep(
    `Enter the <strong>response code sent to your mobile number.</strong>`
  ),
  addStep(`Follow the instructions until the transaction is complete`),
];

const eng2 = [
  addStep(`Select the <strong>Payment menu.</strong>`),
  addStep(`Select the <strong>Bill Payment menu > Virtual Account.</strong>`),
  addStep(`Enter your <strong>Permata OY! Virtual Account number.</strong>`),
  addStep(`Enter the response code sent to your mobile number.`),
  addStep(`Follow the instructions until the transaction is complete.`),
];

export const ListPaymentStepInd = [ind0, ind1, ind2];
export const ListPaymentStepEng = [eng0, eng1, eng2];
