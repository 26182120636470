const addStep = (children: string) => ({ children });

const ind0 = [
  addStep(`Masukkan  <strong>kartu ATM dan PIN BCA kamu. </strong>`),
  addStep(`Pilih menu <strong> Transfer > BCA Virtual Account.</strong>`),
  addStep(`Masukkan nomor  <strong>Virtual Account BCA OY! <strong> Kamu.`),
  addStep(`Pembayaran selesai. Simpan struk sebagai bukti pembayaran.`),
];

const ind1 = [
  addStep(`Login ke Klik BCA`),
  addStep(
    `Pilih <strong>Fund Transfer </strong> > <strong>Transfer to BCA Virtual Account</strong>`
  ),
  addStep(`Masukkan nomor Virtual Account BCA OY! </strong> Anda`),
  addStep(`Pilih Lanjutkan`),
  addStep(
    `Pastikan  <strong>token BCA </strong> Anda  <strong>sudah aktif </strong>`
  ),
  addStep(
    `Pembayaran selesai. <strong> Cetak nomor referensi sebagai bukti pembayaran. </strong>`
  ),
];

const ind2 = [
  addStep(`Login ke  <strong>m BCA. </strong>`),
  addStep(`Pilih <strong> m Transfer > Transfer BCA Virtual Account</strong>`),
  addStep(
    `Pilih menu <strong> input baru > </strong> Masukkan <strong> nomor Virtual Account. </strong>`
  ),
  addStep(
    `Periksa  <strong>jumlah tagihan yang harus dibayarkan </strong>, jika sesuai klik <strong>Ya.</strong>`
  ),
  addStep(`Transaksi selesai.`),
];

const eng0 = [
  addStep(`Enter your  <strong>ATM card and BCA PIN. </strong>`),
  addStep(`Select the  <strong>Transfer menu > BCA Virtual Account. </strong>`),
  addStep(`Enter your  <strong>BCA OY! Virtual Account number. </strong>`),
  addStep(`Payment completed. Save the receipt as proof of payment`),
];

const eng1 = [
  addStep(`Login to Click BCA`),
  addStep(
    `Select  <strong>Fund Transfer > Transfer to BCA Virtual Account </strong>`
  ),
  addStep(`Enter your  <strong>BCA OY! Virtual Account number! </strong>`),
  addStep(`Select Continue`),
  addStep(`Make sure your  <strong>BCA token is active </strong>`),
  addStep(`Payment completed. Print the reference number as proof of payment.`),
];

const eng2 = [
  addStep(`Login to mBCA.`),
  addStep(`Select m Transfer > Transfer BCA Virtual Account`),
  addStep(`Enter your BCA OY! Virtual Account number!`),
  addStep(`Enter your mBCA PIN.`),
  addStep(`Payment completed.`),
];

export const ListPaymentStepInd = [ind0, ind1, ind2];
export const ListPaymentStepEng = [eng0, eng1, eng2];
