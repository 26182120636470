import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { ListPaymentStepInd, ListPaymentStepEng } from './ListPaymentStep'

import BodyPayment from 'layout/bodypaymentPage'

const PERMATAPayment = () => {
  const [tabsList, setTabsList] = useState<ITabList[]>([
    {
      id: 0,
      text: 'ATMPERMATA',
    },
    {
      id: 1,
      text: 'PERMATAMOBILE',
    },
    {
      id: 2,
      text: 'PERMATANET',
    },
  ])

  const [selectedTab, setSelectedTab] = useState(0)

  const { i18n } = useTranslation()

  return (
    <React.Fragment>
      <BodyPayment
        {...{ tabsList, selectedTab, setSelectedTab, setTabsList }}
        ListPaymentStep={
          i18n.language === 'ENG' ? ListPaymentStepEng : ListPaymentStepInd
        }
      />
    </React.Fragment>
  )
}

export default PERMATAPayment
