import React, { useEffect } from 'react'

import start from 'assets/startup-flatline-2@3x.png'

import Layout from 'layout/layout'

import './styles.scss'
import { RootState } from 'stores'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

const Rederect = () => {
  const { redirectUrl } = useSelector((state: RootState) => state.validate)
  const { t } = useTranslation()

  useEffect(() => {
    let url = redirectUrl
    if (url !== '') {
      if (url.includes('.')) {
        window.location.replace(url)
      } else {
        window.location.href = url
      }
    }
  }, [redirectUrl])

  return (
    <React.Fragment>
      <Layout>
        <div className="redirect">
          <div className="redirect-content">
            <img src={start} alt="log-not" />
            <h5>{t('MohonBersabar')}...</h5>
            <p>{t('redirect')}</p>
            <div style={{ height: '100px' }}>
              <span className="loader"></span>
            </div>
            Loading
          </div>
        </div>
        <div className="text-center" style={{ color: '#b8b8b8', bottom: 0 }}>
          v {process.env.REACT_APP_KORALANTAS_VERSION}
        </div>
      </Layout>
    </React.Fragment>
  )
}

export default Rederect
