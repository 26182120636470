import { lazy } from 'react'
import invalidParam from 'views/invalidParam'
import NotFound from 'views/notFound'
import PaymentPage from 'views/paymentPage'
import Rederect from 'views/redirect'

const Home = lazy(() => import('./views/home'))
const PaymentMethods = lazy(() => import('./views/paymentMethods'))

const routes = [
  {
    path: '/invalid-page',
    exact: true,
    name: 'InvalidParams',
    component: invalidParam,
  },
  {
    path: '/web/:orderNumber',
    exact: true,
    name: 'Home',
    component: Home,
  },
  {
    path: '/payment-channel/:orderNumber',
    exact: true,
    name: 'PaymentMethods',
    component: PaymentMethods,
  },
  {
    path: '/payment/:orderNumber',
    exact: true,
    name: 'paymantPage',
    component: PaymentPage,
  },
  {
    path: '/',
    exact: true,
    name: 'Home',
    component: Home,
  },
  {
    path: '/redirect',
    exact: true,
    name: 'Redirect',
    component: Rederect,
  },
  {
    path: '*',
    exact: true,
    name: 'NotFound',
    component: NotFound,
  },
]

export default routes
