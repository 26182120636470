const addStep = (children: string) => ({ children });

const ind0 = [
  addStep(`Masukkan  <strong>kartu ATM dan PIN. </strong>`),
  addStep(`Pilih menu <strong>Pembayaran > Tagihan Lainnya.</strong>`),
  addStep(`Masukkan <strong>nomor Virtual Account.</strong>`),
  addStep(`Masukkan <strong>nominal Pembayaran.</strong>`),
  addStep(`Transaksi selesai.`),
];

const ind1 = [
  addStep(`Masukkan  <strong>User ID dan Password </strong>`),
  addStep(`Pilih menu <strong> Pembayaran > Tagihan Lainnya. </strong>`),
  addStep(
    `Masukkan <strong>nomor Virtual Account</strong> dan <strong>nominal Pembayaran.</strong>`
  ),
  addStep(
    `Periksa jumlah tagihan yang harus  <strong>dibayarkan </strong>, jika sesuai  <strong>klik Ya. </strong>`
  ),
  addStep(`Masukkan  <strong>OTP dan PIN transaksi. </strong>`),
  addStep(`Transaksi selesai.`),
];

const ind2 = [
  addStep(`Masukkan  <strong>Password. </strong>`),
  addStep(`Pilih menu  <strong>Pembayaran > Virtual Account. </strong>`),
  addStep(`Masukkan  <strong>nomor Virtual Account. </strong>`),
  addStep(`Masukkan  <strong>nominal pembayaran dan PIN. </strong>`),
  addStep(`Transaksi selesai.`),
];

const eng0 = [
  addStep(`Enter  <strong>the ATM card and PIN. </strong>`),
  addStep(`Select  <strong>the Payment menu > Other Bills. </strong>`),
  addStep(`Enter the <strong>Virtual Account Number</strong> `),
  addStep(`Enter the <strong>Payment amount </strong>`),
  addStep(`Transaction completed`),
];

const eng1 = [
  addStep(`Enter  <strong>User ID and Password. </strong>`),
  addStep(`Select the <strong> Payment menu > Other Bills.</strong>`),
  addStep(
    `Enter the <strong> Virtual Account number </strong> and <strong>Payment amount.</strong>`
  ),
  addStep(`Enter  <strong>OTP and transaction PIN. </strong>`),
  addStep(`Transaction completed.`),
];

const eng2 = [
  addStep(`Enter  <strong>Password. </strong>`),
  addStep(`Select the  <strong>Payment menu > Virtual Account. </strong>`),
  addStep(`Enter the  <strong>Virtual Account number. </strong>`),
  addStep(`Enter the  <strong>Payment amount and PIN. </strong>`),
  addStep(`Transaction completed.`),
];

export const ListPaymentStepInd = [ind0, ind1, ind2];
export const ListPaymentStepEng = [eng0, eng1, eng2];
