import { Dispatch } from 'redux'
import { RootState } from 'stores'
import {
  ISetIframeCC,
  ISetLoading,
  ISetValidate,
  IValidateState,
  ValidateTypes,
} from 'stores/types/validateTypes'
import { apiPostToken } from './api'
import constant from 'config/constant'
import { MerchantTypes } from 'stores/types/merchantTypes'
import { errorHandleAPI, getData } from './index'
import { ClientJS } from 'clientjs'

export const getValidateWeb = (orderNumber: string, signature?: string) => (
  dispatch: any,
  getState: () => RootState,
) => {
  dispatch(setLoading(true))

  const client = new ClientJS()
  const fingerprint = client.getFingerprint()

  let data = {
    OrderNumber: orderNumber,
    BrowserId: fingerprint,
  }

  apiPostToken(constant.URL_VALIDATE_WEB_VIEW, data, signature)
    .then((res: any) => {
      if (res.data.status_code === 200) {
        if (res.data.data.has_payno === true) {
          dispatch(
            setValidate(
              'Anda Sudah Mendapatkan Nomor VA, Silahkan Cek Email Anda',
            ),
          )
        } else {
          let merchantTheme = getState().merchant.merchantTheme

          merchantTheme = res.data.data.merchant.theme

          let redirectUrl = res.data.data.redirect_finish_url

          dispatch({
            type: ValidateTypes.SET_REDIRECT_URL,
            payload: { redirectUrl },
          })

          dispatch({
            type: MerchantTypes.SET_MERCHANT_THEME,
            payload: merchantTheme,
          })

          dispatch({
            type: ValidateTypes.SET_SIGNATURE,
            payload: { signature: res.data.data.signature },
          })

          dispatch({
            type: ValidateTypes.SET_ORDER_NUMBER,
            payload: { orderNumber },
          })

          dispatch(getData(orderNumber))
        }
      } else {
        dispatch(setValidate('Batas Waktu Transaksi Anda Telah Berakhir'))
      }
    })
    .catch(function (error) {
      dispatch(setLoading(false))
      let isTrace = dispatch(errorHandleAPI(error))
      if (isTrace === false) {
        if (error.response) {
          dispatch(setValidate('ERROR'))
        }
      }
    })
}

export const redirectFinish = () => (
  dispatch: any,
  getState: () => RootState,
) => {
  let url = getState().validate.redirectUrl
  window.location.href = url
}

export const setValidate = (data: IValidateState['messageIsValid']) => (
  dispatch: Dispatch<ISetValidate>,
) => {
  dispatch({
    type: ValidateTypes.SET_VALIDATE,
    payload: {
      isLoading: false,
      messageIsValid: data,
      isValidate: false,
    },
  })
}

export const setLoading = (data: IValidateState['isLoading']) => (
  dispatch: Dispatch<ISetLoading>,
) => {
  dispatch({
    type: ValidateTypes.SET_LOADING,
    payload: {
      isLoading: data,
    },
  })
}

export const setCCFrame = (data: IValidateState['urlIframeCC']) => (
  dispatch: Dispatch<ISetIframeCC>,
) => {
  dispatch({
    type: ValidateTypes.SET_CC_FRAME,
    payload: {
      urlIframeCC: data,
    },
  })
}
