import React from 'react'
import { withTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { RootState } from 'stores'

import cancel_circle from 'assets/cancel-circle.png'

import './styles.scss'
import Layout from 'layout/layout'

const InvalidPage = () => {
  const validate = useSelector((state: RootState) => state.validate)

  return (
    <React.Fragment>
      <Layout>
        <div className="d-flex flex-column justify-content-center align-items-center h-100">
          <img
            style={{ width: 105, height: 105 }}
            src={cancel_circle}
            alt="not-found"
          />
          <div className="not-found-text">{validate.messageIsValid}</div>
        </div>
      </Layout>
    </React.Fragment>
  )
}

export default withTranslation()(InvalidPage)
