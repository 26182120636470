import {
  ITransactionState,
  ITransaction,
  TransactionTypes,
} from './../types/transactionTypes'

const initialState = {
  orderNumber: '',
  signature: '',
  orderDetail: [],
  isLoading: false,
  error: null,
  prices: {
    price: 0,
    priceCharge: 0,
    chargeSystem: 0,
    currency: '',
    totalAmount: 0,
    totalProduct: 0,
    serviceFee: 0,
  },
  paymentLink: {
    trxReferenceNo: '',
    trxPaymentlinkCodeGroup: '',
    type: null,
    payer: 0,
    amount: 0,
    messageAmount: '',
    isDisabledAmount: true,
    trxId: null,
    isPayLink: false,
  },
}

const Transaction = (
  state: ITransactionState = initialState,
  { type, payload }: ITransaction,
) => {
  switch (type) {
    case TransactionTypes.SET_TRANSACTION_PRICES:
      return { ...state, ...payload }
    case TransactionTypes.SET_TRANSACTION_ORDER_DETAIL:
      return { ...state, ...payload }
    default:
      return state
  }
}

export default Transaction
