const addStep = (children: string) => ({ children });

const ind0 = [
  addStep(`Login ke CIMB click (https://www.cimbclicks.co.id )`),
  addStep(`Pilih menu Bayar Tagihan.`),
  addStep(
    `Pilih <strong> Rekening Sumber dan Jenis Pembayaran > Virtual Account. </strong>`
  ),
  addStep(`Masukkan <strong> nomor Virtual Account CIMB kamu. </strong>`),
  addStep(
    `Masukkan <strong>6 digit mPIN </strong> dan <strong>tekan tombol Submit.</strong>`
  ),
  addStep(`Konfirmasi  pembayaran akan ditampilkan pada layar.`),
];

const ind1 = [
  addStep(`Login ke Go Mobile.`),
  addStep(
    `Pilih menu <strong> TRANSFER > Transfer to Other CIMB Niaga Account. </strong>`
  ),
  addStep(
    `Pilih rekening sumber <strong> CASA atau Rekening Ponsel. </strong>`
  ),
  addStep(
    `Pilih <strong>CASA</strong> dan masukkan <strong>nomor Virtual Account CIMB OY! kamu.</strong>`
  ),
  addStep(`Masukkan jumlah pembayaran sesuai tagihan.`),
  addStep(`Masukkan Mobile Banking PIN.`),
  addStep(`Konfirmasi pembayaran ditampilkan pada layar.`),
];

const ind2 = [
  addStep(`Masukkan <strong>Kartu ATM dan PIN CIMB kamu.</strong>`),
  addStep(`Pilih menu <strong>Pembayaran > Lanjut > Virtual Account.</strong>`),
  addStep(`Masukkan nomor Virtual Account CIMB OY! kamu.`),
  addStep(`Pilih rekening debit.`),
  addStep(`Pilih <strong>OK</strong> untuk melakukan pembayaran.`),
  addStep(`Konfirmasi pembayaran ditampilkan pada layar.`),
];
const eng0 = [
  addStep(`Login to CIMB click (https://www.cimbclicks.co.id )`),
  addStep(`Select the Pay Bills menu.`),
  addStep(
    `Select <strong> Source Account and Payment Type > Virtual Account.</strong>`
  ),
  addStep(`Enter your <strong>CIMB Virtual Account number.</strong>`),
  addStep(
    `Enter <strong>the 6 digit mPIN and press the Submit button.</strong>`
  ),
  addStep(`Payment confirmation will be displayed on the screen.`),
];

const eng1 = [
  addStep(`Login to Go Mobile.`),
  addStep(
    `Select the <strong>Transfer menu > Transfer to Other CIMB Niaga Account.</strong>`
  ),
  addStep(
    `Select the <strong>source account: CASA or Mobile Account.</strong>`
  ),
  addStep(
    `Select <strong>CASA and enter your CIMB OY! Virtual Account number.</strong>`
  ),
  addStep(`Enter the <strong>payment amount according to the bill.</strong>`),
  addStep(`Enter the <strong>Mobile Banking PIN.</strong>`),
  addStep(`Payment confirmation is displayed on the screen.`),
];

const eng2 = [
  addStep(`Enter your <strong>ATM Card and CIMB PIN</strong>.`),
  addStep(
    `Select the <strong>Payment menu > Continue > Virtual Account.</strong>`
  ),
  addStep(`Enter your <strong>CIMB OY! Virtual Account number</strong>`),
  addStep(`Select a <strong>Debit account.</strong>`),
  addStep(`Select <strong>OK</strong> to <strong>make payment.</strong>`),
  addStep(`Payment <strong>confirmation is displayed on the screen.</strong>`),
];

export const ListPaymentStepInd = [ind0, ind1, ind2];
export const ListPaymentStepEng = [eng0, eng1, eng2];
