import React from 'react'

import Footer from 'components/Footer'
import not from './../../assets/404-page-not-found-monochromatic-2.png'

import Layout from 'layout/layout'
import { useTranslation } from 'react-i18next'
import Header from 'layout/header'

const NotFound = () => {
  const { t } = useTranslation()

  return (
    <Layout>
      <Header not />
      <div className="text-center m-5">
        <div className="h-100 d-flex p-4 justify-content-center flex-column w-100 align-items-center">
          <img style={{ width: 150 }} src={not} alt="log-not" />
          <h5 className="mt-4" style={{ fontWeight: 'bold' }}>
            {t('notFound1')}
          </h5>
          <p style={{ textAlign: 'center', fontSize: '12px' }}>
            {t('notFound2')}
          </p>
        </div>
      </div>
      <Footer textFooter="Kembali Ke Beranda" submit={undefined} />
    </Layout>
  )
}

export default NotFound
