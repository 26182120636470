const addStep = (children: string) => ({ children });

const ind0 = [
  addStep(`Masukkan kartu debit/ATM kamu.`),
  addStep(`Masukkan <strong>PIN kamu.</strong>`),
  addStep(`Pilih  <strong>Menu Lain > Transfer. </strong>`),
  addStep(`Pilih rekening asal dan pilih rekening tujuan ke rekening BNI`),
  addStep(`Masukkan <strong> nomor Virtual Account BNI OY! kamu. </strong>`),
  addStep(`Masukkan <strong>jumlah nominal.</strong>`),
  addStep(`Ikuti petunjuk hingga transaksi selesai.`),
];

const ind1 = [
  addStep(`Pilih <strong> Transfer > Antar Rekening BNI.</strong>`),
  addStep(`Pilih <strong>Rekening Tujuan > Input Rekening Baru.</strong>`),
  addStep(
    `Masukkan <strong>nomor Rekening</strong> dan <strong>nomor Virtual Account.</strong>`
  ),
  addStep(`Masukkan <strong>jumlah nominal pembayaran > Lanjutkan.</strong>`),
  addStep(`Periksa detail konfirmasi.`),
  addStep(`Ikuti petunjuk hingga transaksi selesai.`),
];

const ind2 = [
  addStep(
    `Pilih Transaksi > Info & Administrasi Transfer > Atur Rekening Tujuan > Tambah. Rekening Tujuan dan klik OK.`
  ),
  addStep(
    `Pilih <strong> Kode Network & Bank </strong> Transfer Antar Rek. BNI.`
  ),
  addStep(
    `Masukkan <strong> nomor rekening </strong> dan <strong> nomor Virtual Account. </strong>`
  ),
  addStep(`Periksa detail <strong> konfirmasi > Masukkan PIN.</strong>`),
  addStep(`Ikuti petunjuk hingga transaksi selesai.`),
];
const eng0 = [
  addStep(`Enter your Debit/ATM card.`),
  addStep(`Enter your PIN.`),
  addStep(`Select  <strong>Other Menu > Transfer. </strong>`),
  addStep(
    `Select the origin account and select the destination account to the BNI account`
  ),
  addStep(`Enter <strong> your BNI OY! Virtual Account number. </strong>`),
  addStep(`Enter the  <strong>nominal amount. </strong>`),
  addStep(`Follow the instructions until the transaction is complete.`),
];

const eng1 = [
  addStep(`Select Transfer > Between BNI Accounts.`),
  addStep(`Select Destination Account > Input New Account.`),
  addStep(`Enter the Account number and Virtual Account number.`),
  addStep(`Enter the payment amount > Continue.`),
  addStep(`Check confirmation details.`),
  addStep(`Follow the instructions until the transaction is complete.`),
];

const eng2 = [
  addStep(
    `Select Transaction > Transfer Info & Administration > Set Destination Account > Add Destination Account and click OK.`
  ),
  addStep(`Select Network & Bank Code: Transfer Between Accounts. BNI.`),
  addStep(`Enter the account number and Virtual Account number.`),
  addStep(`Check confirmation details > Enter PIN.`),
  addStep(`Follow the instructions until the transaction is complete`),
];

export const ListPaymentStepInd = [ind0, ind1, ind2];
export const ListPaymentStepEng = [eng0, eng1, eng2];
