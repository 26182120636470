import { AxiosError } from 'axios'

export enum TransactionTypes {
  GET_TRANSACTION_PENDING = 'GET_TRANSACTION_PENDING',
  GET_TRANSACTION_SUCCESS = 'GET_TRANSACTION_SUCCESS',
  GET_TRANSACTION_FAILED = 'GET_TRANSACTION_FAILED',

  SET_TRANSACTION_SIGNATURE = 'SET_TRANSACTION_SIGNATURE',
  SET_TRANSACTION_ORDERNUMBER = 'SET_TRANSACTION_ORDERNUMBER',
  SET_TRANSACTION_PRICES = 'SET_TRANSACTION_PRICES',
  SET_TRANSACTION_PAYMENTLINK = 'SET_TRANSACTION_PAYMENTLINK',
  SET_TRANSACTION_ORDER_DETAIL = 'SET_TRANSACTION_ORDER_DETAIL',
}

export interface ITransactionState {
  orderNumber: string
  signature: string
  prices: ITransactionPrices
  orderDetail: any[]
  paymentLink: ITransactionPaymentLink
  isLoading: boolean
  error: null | AxiosError
}

export interface ITransactionPrices {
  price: number
  priceCharge: number
  chargeSystem: number
  currency: string
  totalAmount: number
  totalProduct: number
  serviceFee: number
}

export interface ITransactionPaymentLink {
  trxReferenceNo: string
  trxPaymentlinkCodeGroup: string
  type: null | Number
  payer: Number
  amount: Number
  messageAmount: string
  isDisabledAmount: boolean
  trxId: null | string
  isPayLink: boolean
}

export interface ISetTransactionPrice {
  type: TransactionTypes.SET_TRANSACTION_PRICES
  payload: {
    prices: ITransactionState['prices']
  }
}

export interface IGetTransactionFailed {
  type: TransactionTypes.GET_TRANSACTION_FAILED
  payload: {
    isLoading: false
  }
}

export interface ISetTransactionOrder {
  type: TransactionTypes.SET_TRANSACTION_ORDER_DETAIL
  payload: {
    orderDetail: ITransactionState['orderDetail']
  }
}

export type ITransaction = ISetTransactionPrice | ISetTransactionOrder
