import React from 'react'
import { useTranslation } from 'react-i18next'
import './style.scss'
import Version from './version'

const IFooter = ({ submit, textFooter = '', bottom }: IFooter) => {
  const { t } = useTranslation()
  return (
    <div className={bottom ? 'footer' : ''}>
      <button onClick={() => submit()} className="buttonSubmit">
        {t(textFooter)}
      </button>
      <Version />
    </div>
  )
}

export default IFooter
