import "./layout.scss"

interface Ibody {
    children: null | any | any[];
    styles?: Object;
}

const BodyContent = ({
    children,
    styles = {}
}: Ibody) => {

    return (
        <div className="card-container">
            <div className="content-cont" style={styles}>
                {children}
            </div>
        </div>
    )
}

export default BodyContent