import { AxiosError } from 'axios'

export enum PaymentMethodTypes {
  GET_PAYMENTMETHOD_PENDING = 'GET_PAYMENTMETHOD_PENDING',
  GET_PAYMENTMETHOD_SUCCESS = 'GET_PAYMENTMETHOD_SUCCESS',
  GET_PAYMENTMETHOD_FAILED = 'GET_PAYMENTMETHOD_FAILED',
  SET_PAYMENTMETHOD_LIST = 'SET_PAYMENTMETHOD_LIST',
  SET_PAYMENTMETHOD = 'SET_PAYMENTMETHOD',
  CLEAR_PAYMENT_METHOD = 'CLEAR_PAYMENT_METHOD',
  CLEAR_PAYMENT_METHODLIST = 'CLEAR_PAYMENT_METHODLIST',
}

export interface IPaymentMethodState {
  paymentMethodList: IPaymentMethodData[]
  payment: boolean
  paymentMethod: IPaymentChannel
  isPaymentSubmit: boolean
  isLoading: boolean
  error: null | AxiosError
}

export interface IPaymentMethodData {
  payment_channels: any
  id: Number
  name: string
  picture: {
    url: string
  }
  chanels: IPaymentChannel[]
}

export interface IPaymentChannel {
  id: Number
  name: string
  picture: {
    url: string
  }
  page_id: number
}
export interface ISetPaymentMethodList {
  type: PaymentMethodTypes.SET_PAYMENTMETHOD_LIST
  payload: {
    paymentMethodList: IPaymentMethodState['paymentMethodList']
  }
}

export interface ISetPaymentMethod {
  type: PaymentMethodTypes.SET_PAYMENTMETHOD
  payload: {
    paymentMethod: IPaymentMethodState['paymentMethod']
    isPaymentSubmit: IPaymentMethodState['isPaymentSubmit']
  }
}
export interface IClearPaymentMethod {
  type: PaymentMethodTypes.CLEAR_PAYMENT_METHOD
  payload: {
    paymentMethod: IPaymentMethodState['paymentMethod']
    isPaymentSubmit: IPaymentMethodState['isPaymentSubmit']
  }
}
export interface IClearPaymentMethodList {
  type: PaymentMethodTypes.CLEAR_PAYMENT_METHODLIST
  payload: {
    paymentMethodList: IPaymentMethodState['paymentMethodList']
  }
}

export type IPaymentMethod =
  | ISetPaymentMethodList
  | ISetPaymentMethod
  | IClearPaymentMethod
  | IClearPaymentMethodList
