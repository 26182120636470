import {
  IRekeningState,
  IRekening,
  RekeningTypes,
} from 'stores/types/rekeningTypes'

const initialState = {
  signatureVa: '',
  ticketId: '',
  showRekening: false,
  bankVA: {
    nomorRekening: '',
    expiredDate: '',
  },
  creditCard: {
    account_number: '',
    exp_month: '',
    exp_year: '',
    cvn: '',
  },
  qris: {
    value: '',
    expire: '',
  },
}

const Rekening = (
  state: IRekeningState = initialState,
  { type, payload }: IRekening,
) => {
  switch (type) {
    case RekeningTypes.SET_SIGNATUREVA:
      return { ...state, ...payload }
    case RekeningTypes.SET_PAYMENTVA:
      return { ...state, ...payload }
    case RekeningTypes.SET_CREDIT_CARD_DATA:
      return { ...state, ...payload }
    case RekeningTypes.SET_QRIS:
      return { ...state, ...payload }
    default:
      return state
  }
}

export default Rekening
